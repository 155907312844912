import 'reflect-metadata';

// eslint-disable-next-line prettier/prettier
import { UIProvider } from '@asgard/ui-library';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import { config as FAConfig } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import * as snippet from '@segment/snippet';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import GlobalStyles from 'components/layout/Global.styled';
import LoggedUserProvider from 'context/logged-user/loggeduser.context';
import { ManfrediteFieldsStatusProvider } from 'context/manfredite-fields-status/manfredite-fields-status.provider';
import OfferApplyModalProvider from 'context/offer-apply-modal/offerapplymodal.context';
import SlugsProvider from 'context/slugs/slugs.context';
import ToastProvider from 'context/toasts/toast.context';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import { IState } from 'flagsmith/types';
import { appWithTranslation } from 'next-i18next';
import { AppProps } from 'next/app';
import { useRouter } from 'next/dist/client/router';
import Head from 'next/head';
import Script from 'next/script';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import ReactModal from 'react-modal';
import 'reflect-metadata';
import { ThemeProvider } from 'styled-components';
import { SWRConfig } from 'swr';
import theme from 'theme';
import { config } from 'utils/config';

// See: https://fontawesome.com/docs/web/use-with/react/use-with
FAConfig.autoAddCss = false;

function renderSegmentSnippet(): string {
  const opts: snippet.Options = {
    apiKey: config.segmentApiKey || '',
    page: false,
    useHostForBundles: true,
    host: config.segmentCustomCDN,
    load: !config.segmentCustomProxyUrl
  };

  if (process.env.NODE_ENV === 'development') {
    return snippet.max(opts);
  }

  return snippet.min(opts);
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function App({
  Component,
  pageProps
}: AppProps & {
  pageProps:
    | {
        fallback?: { [key: string]: any };
        flagsmithState?: IState<string, string>;
      }
    | undefined;
}) {
  const router = useRouter();

  ReactModal.setAppElement('body');

  useEffect(() => {
    const gtmId = config.gtmId;

    if (gtmId) {
      TagManager.initialize({ gtmId });
    }
  }, []);

  useEffect(() => {
    function routeChangeStartHandler(): void {
      document.documentElement.classList.add('normal-scroll');
    }

    function routeChangeCompleteHandler(): void {
      document.documentElement.classList.remove('normal-scroll');
    }

    router.events.on('routeChangeStart', routeChangeStartHandler);
    router.events.on('routeChangeComplete', routeChangeCompleteHandler);

    return () => {
      router.events.off('routeChangeStart', routeChangeStartHandler);
      router.events.off('routeChangeComplete', routeChangeCompleteHandler);
    };
  }, []);

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <UIProvider theme={theme}>
          <SWRConfig
            value={{
              fallback:
                'fallback' in pageProps
                  ? (pageProps.fallback as { [key: string]: any })
                  : undefined
            }}
          >
            <FlagsmithProvider
              flagsmith={flagsmith}
              {...(typeof window !== 'undefined' && {
                options: {
                  environmentID: config.flagsmithEnvironmentKey
                }
              })}
              serverState={
                'flagsmithState' in pageProps
                  ? (pageProps.flagsmithState as IState<string, string>)
                  : undefined
              }
            >
              <ToastProvider>
                <UserProvider>
                  <SlugsProvider>
                    <LoggedUserProvider>
                      <ManfrediteFieldsStatusProvider>
                        <OfferApplyModalProvider>
                          <Head>
                            <meta
                              name="viewport"
                              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
                            />
                            <meta
                              property="og:locale"
                              content={router.locale}
                            />
                            <meta property="og:type" content="website" />
                            <meta property="og:site_name" content="Manfred" />
                          </Head>

                          {config.segmentApiKey && (
                            <Script
                              id="segment-script"
                              dangerouslySetInnerHTML={{
                                __html: renderSegmentSnippet()
                              }}
                            />
                          )}

                          {config.segmentApiKey &&
                            config.segmentCustomProxyUrl && (
                              <Script
                                id="segment-config-script"
                                dangerouslySetInnerHTML={{
                                  __html: `analytics.load("${config.segmentApiKey}", { integrations: { "Segment.io": { apiHost: "${config.segmentCustomProxyUrl}/v1" }}})`
                                }}
                              />
                            )}

                          <Script
                            id="Cookiebot"
                            src="https://consent.cookiebot.com/uc.js"
                            data-cbid="56969df0-13db-4825-aac7-bf19c0786c5e"
                            data-culture={router.locale?.toUpperCase() || 'EN'}
                            data-blockingmode="auto"
                            type="text/javascript"
                          ></Script>

                          <GlobalStyles />

                          <Component {...pageProps} />
                        </OfferApplyModalProvider>
                      </ManfrediteFieldsStatusProvider>
                    </LoggedUserProvider>
                  </SlugsProvider>
                </UserProvider>
              </ToastProvider>
            </FlagsmithProvider>
          </SWRConfig>
        </UIProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default appWithTranslation(App);
