import { SalaryRange } from 'interfaces/manfredite.interface';
import { WebOffer } from 'model/model.web-offer';
import { getTranslation } from 'utils/i18n';

export function getSalaryRangeText(
  { min, max, currency }: SalaryRange,
  minMax?: boolean,
  locale?: string
): string {
  if (min && !max) {
    return (
      getTranslation(minMax ? 'salary.min' : 'salary.from', 'common', locale, {
        min: getKSalary(min),
        currency
      }) || ''
    );
  }

  if (!min && max) {
    return getTranslation(
      minMax ? 'salary.max' : 'salary.up_to',
      'common',
      locale,
      {
        max: getKSalary(max),
        currency
      }
    );
  }

  if (min === max) {
    return getTranslation('salary.equal', 'common', locale, {
      min: getKSalary(min),
      max: getKSalary(max),
      currency
    });
  }

  return getTranslation('salary.minMax', 'common', locale, {
    min: getKSalary(min),
    max: getKSalary(max),
    currency
  });
}

export function getSalaryHourlyRate(
  offer: WebOffer,
  locale: string = 'es'
): string {
  const { hourlyRateFrom: min, hourlyRateTo: max, currency } = offer;

  if (min && !max) {
    return (
      getTranslation('salary.freelanceFee.min', 'common', locale, {
        min,
        currency: currency || '€'
      }) || ''
    );
  }

  if (!min && max) {
    return getTranslation('salary.freelanceFee.max', 'common', locale, {
      max: max,
      currency: currency || '€'
    });
  }

  if (min && max) {
    if (min === max) {
      return getTranslation('salary.freelanceFee.equal', 'common', locale, {
        min,
        max,
        currency: currency || '€'
      });
    }

    return getTranslation('salary.freelanceFee.minMax', 'common', locale, {
      min,
      max,
      currency: currency || '€'
    });
  }

  return '';
}

/**
 * Get the salary in K format (without the letter K)
 *
 * @param salary - salary
 * @returns the salary converted to K format
 */
export function getKSalary(salary: number): number {
  return salary / 1000;
}
